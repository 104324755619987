import { DropdownModel } from '../geral/DropdownModel';
import { cloneDeep } from 'lodash';
export class OrcamentoModel {
  constructor({
    id,
    orcamentoOrigemId,
    participanteClienteId,
    participanteClienteNome,
    participanteFornecedorId,
    participanteFornecedorNome,
    codigo,
    responsavelUsuarioId,
    responsavelUsuarioNome,
    ordemServicoCodigo,
    ordemServico,
    ordemServicoId,
    dataEmissao = new Date(),
    prazoGarantia,
    flagEmElaboracao = false,
    numeroParcela = 1,
    flagCondicaoPagamentoPorContrato = false,
    condicaoPagamento,
    numeroPedidoCompra,
    formaSolicitacaoOrcamento = 1,
    descricaoFormaSolicitacaoOrcamento,
    localExecucaoId,
    validadeProposta = 30,
    tipoDesconto = 'Percentual',
    valorTotalFrete,
    observacao,
    valorTotal,
    valorTotalItens,
    valorTotalDesconto,
    valorTotalOutrosDespesas,
    orcamentoItem,
    valorTotalFinal,
    participanteClienteContatoId,
    status,
    orcamentoMensagem,
  }) {
    this.id = id;
    this.orcamentoOrigemId = orcamentoOrigemId;
    this.participanteClienteId = participanteClienteId;
    this.participanteClienteNome = participanteClienteNome;
    this.participanteFornecedorId = participanteFornecedorId;
    this.participanteFornecedorNome = participanteFornecedorNome;
    this.codigo = codigo;
    this.responsavelUsuarioId = responsavelUsuarioId;
    this.responsavelUsuarioNome = responsavelUsuarioNome;
    this.ordemServicoCodigo = ordemServicoCodigo;
    this.ordemServico = ordemServico;
    this.ordemServicoId = ordemServicoId;
    this.dataEmissao = dataEmissao;
    this.prazoGarantia = prazoGarantia;
    this.flagEmElaboracao = flagEmElaboracao;
    this.numeroParcela = numeroParcela;
    this.flagCondicaoPagamentoPorContrato = flagCondicaoPagamentoPorContrato;
    this.condicaoPagamentoId = condicaoPagamento;
    if (condicaoPagamento) {
      this.condicaoPagamentoId = new DropdownModel(condicaoPagamento);
    }
    this.numeroPedidoCompra = numeroPedidoCompra;
    this.formaSolicitacaoOrcamento = formaSolicitacaoOrcamento;
    this.descricaoFormaSolicitacaoOrcamento =
      descricaoFormaSolicitacaoOrcamento;
    this.localExecucaoId = localExecucaoId;
    this.validadeProposta = validadeProposta;
    this.tipoDesconto = tipoDesconto;
    this.observacao = observacao;
    this.valorTotal = valorTotal;
    this.valorTotalItens = valorTotalItens;
    this.valorTotalDesconto = valorTotalDesconto;
    this.valorTotalFrete = valorTotalFrete;
    this.valorTotalOutrosDespesas = valorTotalOutrosDespesas;
    this.orcamentoItem = orcamentoItem;
    this.valorTotalFinal = valorTotalFinal;
    this.orcamentoMensagem = orcamentoMensagem ?? [];
    this.participanteClienteContatoId = participanteClienteContatoId;
    this.status = status;

    this.orcamentoMensagem?.forEach((mensagem) => {
      mensagem.flagJaExistente = true;
    });
  }
  get request(){
    let retorno = cloneDeep(this)
    retorno.condicaoPagamentoId = retorno.condicaoPagamentoId?.value;
    return retorno
  }
}
