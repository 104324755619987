<template>
  <div>
    <div class="d-flex mb-8">
      <div>
        <span class="titulo-pagina">
          {{ tituloFormulario }}
        </span>
        <div v-if="form.id && form.codigo">
          <v-chip
            class="mr-1"
            small
          >
            Orçamento: {{ `${form.codigo}` }}
          </v-chip>
        </div>
      </div>
    </div>
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
    >
      <v-tab>
        {{ $t('modulos.orcamentos.formulario.abas.dados_orcamento') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.orcamentos.formulario.abas.itens_orcamento') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.orcamentos.formulario.abas.mensagens') }}
      </v-tab>
      <v-tab :disabled="!id">
        {{ $t('modulos.orcamentos.formulario.abas.comentarios') }}
      </v-tab>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="v-tab"
            v-on="!form.id ? on : null"
          >
            <v-tab
              :disabled="!form.id"
              v-bind="attrs"
            >
              {{ $t('modulos.orcamentos.formulario.abas.anexos') }}
            </v-tab>
          </div>
        </template>
        <span>{{
          $t('modulos.orcamentos.formulario.abas.anexos_bloqueado')
        }}</span>
      </v-tooltip>
      <v-tab>
        {{ $t('modulos.orcamentos.formulario.abas.requisitos') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="indiceAba">
      <v-tab-item>
        <v-form ref="form">
          <div class="row mt-2">
            <input-cliente
              v-model="form.participanteClienteNome"
              class="col-12 col-md-4"
              :label="$t('modulos.orcamentos.formulario.cliente')"
              disabled
            />
            <input-text
              v-model="form.ordemServicoCodigo"
              class="col-12 col-md-2"
              :label="$t('modulos.orcamentos.formulario.cod_os')"
              disabled
            />
            <input-select
              v-model="form.participanteClienteContatoId"
              class="col-12 col-md-2"
              :label="$t('modulos.orcamentos.formulario.aos_cuidados_de')"
              :options="opcoes.aosCuidadosDe"
              disabled
            />
            <input-text
              v-model="form.responsavelUsuarioNome"
              class="col-12 col-md-4"
              :label="$t('modulos.orcamentos.formulario.responsavel')"
              disabled
            />
          </div>
          <div class="row">
            <input-date
              v-model="form.dataEmissao"
              class="col-12 col-md-2"
              :label="$t('modulos.orcamentos.formulario.data_emissao')"
              disabled
            />
            <input-text
              v-model="form.prazoGarantia"
              class="col-12 col-md-2"
              :label="$t('modulos.orcamentos.formulario.garantia_dias')"
              type="number"
              disabled
            />
            <input-text
              v-model="form.validadeProposta"
              class="col-12 col-md-2"
              :label="$t('modulos.orcamentos.formulario.validade_dias')"
              type="number"
              disabled
            />
            <input-text
              v-model="form.participanteFornecedorNome"
              class="col-12 col-md-3"
              :label="$t('modulos.orcamentos.formulario.fornecedor')"
              disabled
            />
            <input-select
              v-model="form.localExecucaoId"
              class="col-12 col-md-3"
              :label="$t('modulos.orcamentos.formulario.local')"
              :options="opcoes.localExecucao"
              disabled
            />
          </div>
          <div class="row">
            <input-radio
              v-model="form.flagCondicaoPagamentoPorContrato"
              class="col-3"
              :divider="false"
              :options="opcoes.booleano"
              :label="
                $t('modulos.orcamentos.formulario.condicao_pagamento_contrato')
              "
              disabled
            />
            <input-text
              v-model="form.numeroParcela"
              type="number"
              class="col-12 col-md-2"
              :label="$t('modulos.orcamentos.formulario.parcelas')"
              disabled
            />
            <input-select
              v-model="form.condicaoPagamentoId"
              class="col-12 col-md-3"
              :label="$t('modulos.orcamentos.formulario.condicao_pagamento')"
              :options="opcoes.condicaoPagamento"
              disabled
            />
            <input-text
              v-model="form.numeroPedidoCompra"
              class="col-12 col-md-2"
              :label="$t('modulos.orcamentos.formulario.pedido_compra')"
              disabled
            />
            <input-select
              v-model="form.formaSolicitacaoOrcamento"
              class="col-12 col-md-2"
              :label="$t('modulos.orcamentos.formulario.como_solicitado')"
              :options="opcoes.formaSolicitacaoOrcamento"
              disabled
            />
          </div>
          <div class="row mb-12">
            <input-radio
              v-model="form.tipoDesconto"
              class="col-4"
              :divider="false"
              :options="opcoes.tipoDesconto"
              :label="$t('modulos.orcamentos.formulario.tipo_desconto')"
              disabled
            />
            <input-radio
              v-model="form.flagEmElaboracao"
              class="col-2"
              :divider="false"
              :options="opcoes.booleano"
              :label="$t('modulos.orcamentos.formulario.em_elaboracao')"
              disabled
            />
            <input-text
              v-model="form.observacao"
              class="col-6"
              :label="$t('modulos.orcamentos.formulario.observacao')"
              disabled
            />
          </div>
        </v-form>
      </v-tab-item>
      <v-tab-item>
        <v-data-table
          :headers="tabela.colunas"
          :items="form.orcamentoItem"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          item-key="numeroItem"
          show-expand
          dense
          hide-default-footer
        >
          <!-- eslint-disable vue/valid-v-slot-->
          <template v-slot:item.numero="{ item }">
            <div
              class="d-flex"
              style="align-items: center"
            >
              <icone-padrao
                :icone="definirIconeAdicionadoRemovido(item).icon"
                :classe-icone="definirIconeAdicionadoRemovido(item).color"
                size="20"
                :tooltip="$t('modulos.orcamentos.situacao')"
              />
              <div class="ml-2">
                {{ item.numeroItem }}
              </div>
            </div>
          </template>
          <template v-slot:item.instrumento="{ item }">
            <div class="d-flex">
              <div
                style="align-self: center"
                class="mr-2"
              >
                <v-icon :disabled="!!item.orcamentoItemDetalhe.length">
                  $search
                </v-icon>
              </div>
              <div class="d-flex">
                <accordion-tabela v-if="item.instrumentosNomes?.length > 1">
                  <template v-slot:header="{ on }">
                    <div
                      class="d-flex"
                      style="width: 125px"
                      v-on="item.instrumentosNomes[0] ? on : null"
                    >
                      <span>{{ item.instrumentosNomes?.length }} </span>
                      <span> {{ '-Instrumentos' }} </span>
                    </div>
                  </template>
                  <div
                    v-for="nome in item.instrumentosNomes"
                    :key="nome"
                  >
                    <v-chip
                      close
                      label
                      outlined
                      color="cinza200"
                      close-icon="$closeBoxOutline"
                    >
                      <span>{{ nome }}</span>
                    </v-chip>
                  </div>
                </accordion-tabela>

                <div v-if="item.instrumentosNomes?.length == 1">
                  <div
                    v-for="nome in item.instrumentosNomes"
                    :key="nome"
                  >
                    <v-chip
                      close
                      label
                      outlined
                      color="cinza200"
                      close-icon="$closeBoxOutline"
                    >
                      <span>{{ nome }}</span>
                    </v-chip>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:item.descricao="{ item }">
            <v-tooltip
              v-if="item.descricao"
              top
              class="pl-0"
            >
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <div class="col-12 cursor-pointer texto-descricao">
                    <span v-html="item.descricao" />
                  </div>
                </div>
              </template>
              <span>{{ item.descricao }}</span>
            </v-tooltip>
            <div v-if="!item.descricao">
              <div>
                <div class="col-12 cursor-pointer texto-descricao">
                  <span v-html="item.descricao" />
                </div>
              </div>
              <span>{{ item.descricao }}</span>
            </div>
          </template>
          <template v-slot:item.prazoEntrega="{ item }">
            <div
              class="col-12 cursor-pointer"
              style="text-align-last: center"
            >
              <span>{{ item.prazoEntrega }}</span>
            </div>
          </template>
          <template v-slot:item.qtd="{ item }">
            <div
              class="col-12 cursor-pointer"
              style="text-align-last: center"
            >
              <span>{{ item.quantidade }}</span>
            </div>
          </template>
          <template v-slot:item.valorUnitario="{ item }">
            <div
              class="col-12 cursor-pointer"
              style="text-align-last: end; padding-right: 0"
            >
              <span>{{
                item.valorUnitario
                  ? item.valorUnitario
                  : '0,00' | formataValorPrecisaoDois
              }}</span>
            </div>
          </template>
          <template v-slot:item.valorTotal="{ item }">
            <div
              class="col-12 cursor-pointer"
              style="text-align-last: end; padding-right: 0"
            >
              <span>{{
                item.valorTotal
                  ? item.valorTotal
                  : '0,00' | formataValorPrecisaoDois
              }}</span>
            </div>
          </template>
          <template v-slot:item.desconto="{ item }">
            <div
              class="col-12 cursor-pointer"
              style="text-align-last: end; padding-right: 0"
            >
              <span>{{
                item.desconto
                  ? item.desconto
                  : '0,00' | formataValorPrecisaoDois
              }}</span>
            </div>
          </template>
          <template v-slot:item.frete="{ item }">
            <div
              class="col-12 cursor-pointer mr-2"
              style="text-align-last: end; padding-right: 0"
            >
              <span>{{
                item.valorFrete
                  ? item.valorFrete
                  : '0,00' | formataValorPrecisaoDois
              }}</span>
            </div>
          </template>
          <template v-slot:item.despesas="{ item }">
            <div
              class="col-12 cursor-pointer"
              style="text-align-last: end; padding-right: 0"
            >
              <span>{{
                item.outrasDespesas
                  ? item.outrasDespesas
                  : '0,00' | formataValorPrecisaoDois
              }}</span>
            </div>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="th-simple-table"
                        scope="col"
                      >
                        {{ $t('modulos.orcamentos.formulario.codigo') }}
                      </th>
                      <th
                        class="th-simple-table"
                        scope="col"
                      >
                        {{ $t('modulos.orcamentos.formulario.descricao') }}
                      </th>
                      <th
                        class="th-simple-table"
                        scope="col"
                        style="text-align: center"
                      >
                        {{ $t('modulos.orcamentos.formulario.prazo') }}
                      </th>
                      <th
                        class="th-simple-table"
                        scope="col"
                        style="text-align: center"
                      >
                        {{ $t('modulos.orcamentos.formulario.qtd') }}
                      </th>
                      <th
                        class="th-simple-table"
                        scope="col"
                        style="text-align: center; min-width: 145px"
                      >
                        {{ $t('modulos.orcamentos.formulario.valor_unitario') }}
                      </th>
                      <th
                        class="th-simple-table"
                        scope="col"
                        style="text-align: center"
                      >
                        {{ $t('modulos.orcamentos.formulario.valor_total') }}
                      </th>
                      <th
                        class="th-simple-table"
                        scope="col"
                        style="text-align: center; min-width: 130px"
                      >
                        {{
                          form.tipoDesconto == 'Percentual'
                            ? $t(
                              'modulos.orcamentos.formulario.desconto_icone_percent'
                            )
                            : $t('modulos.orcamentos.formulario.desconto')
                        }}
                      </th>
                      <th
                        class="th-simple-table"
                        scope="col"
                        style="text-align: center"
                      >
                        {{ $t('modulos.orcamentos.formulario.frete') }}
                      </th>
                      <th
                        class="th-simple-table"
                        scope="col"
                        style="text-align: center"
                      >
                        {{ $t('modulos.orcamentos.formulario.despesas') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="instrumento in item.orcamentoItemDetalhe"
                      :key="instrumento.numeroItem"
                    >
                      <td class="col-3">
                        <div class="d-flex">
                          <icone-padrao
                            :icone="
                              instrumento.flagRemovidoCliente
                                ? '$closeCircleOutline'
                                : '$successCircleOutline'
                            "
                            :classe-icone="`${
                              instrumento.flagRemovidoCliente
                                ? 'danger--text'
                                : 'primary-success-700--text'
                            } mt-2`"
                            size="20"
                            :tooltip="$t('modulos.orcamentos.situacao')"
                          />
                          <span class="mt-2">
                            <icone-padrao
                              v-if="instrumento.itemId || instrumento.item"
                              :icone="'$cogOutline'"
                              :tooltip="'Serviço de Peça'"
                            />
                            <icone-padrao
                              v-if="instrumento.tipoServico == 'Calibracao'"
                              :icone="'$wrenchClock'"
                              :tooltip="'Serviço de Calibração'"
                            />
                            <icone-padrao
                              v-if="instrumento.tipoServico == 'Manutencao'"
                              :icone="'$wrenchCog'"
                              :tooltip="'Serviço de Manutenção'"
                            />
                            {{ instrumento.codigo }}
                          </span>
                        </div>
                      </td>
                      <td class="col-3">
                        {{ instrumento.descricao }}
                      </td>
                      <td class="col-1">
                        <div
                          style="text-align-last: center"
                          class="col-12 cursor-pointer"
                        >
                          <span>{{ instrumento.prazoEntrega }}</span>
                        </div>
                      </td>
                      <td class="col-1">
                        <div
                          style="text-align-last: center"
                          class="col-12 cursor-pointer"
                        >
                          <span>{{ instrumento.quantidade }}</span>
                        </div>
                      </td>
                      <td class="col-1">
                        <div
                          style="text-align-last: end"
                          class="col-12 cursor-pointer"
                        >
                          <span>{{
                            instrumento.valorUnitario
                              ? instrumento.valorUnitario
                              : '0,00' | formataValorPrecisaoDois
                          }}</span>
                        </div>
                      </td>
                      <td class="col-1">
                        <div
                          style="text-align-last: end"
                          class="col-12 cursor-pointer"
                        >
                          <span>{{
                            instrumento.valorTotal
                              ? instrumento.valorTotal
                              : '0,00' | formataValorPrecisaoDois
                          }}</span>
                        </div>
                      </td>
                      <td class="col-1">
                        <div
                          style="text-align-last: end"
                          class="col-12 cursor-pointer"
                        >
                          <span>{{
                            instrumento.desconto
                              ? instrumento.desconto
                              : '0,00' | formataValorPrecisaoDois
                          }}</span>
                        </div>
                      </td>
                      <td class="col-1">
                        <div
                          style="text-align-last: end"
                          class="col-12 cursor-pointer"
                        >
                          <span>{{
                            instrumento.valorFrete
                              ? instrumento.valorFrete
                              : '0,00' | formataValorPrecisaoDois
                          }}</span>
                        </div>
                      </td>
                      <td class="col-1">
                        <div
                          style="text-align-last: end"
                          class="col-12 cursor-pointer"
                        >
                          <span>{{
                            instrumento.outrasDespesas
                              ? instrumento.outrasDespesas
                              : '0,00' | formataValorPrecisaoDois
                          }}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item />
      <v-tab-item />
      <v-tab-item />
      <v-tab-item>
        <requisitos
          :form="form"
          :requisitos-cliente="dadosRequisitosClienteFormatado"
        />
      </v-tab-item>
    </v-tabs-items>

    <div
      v-if="indiceAba !== 4 && indiceAba !== 5"
      class="row mb-4 mt-4 pl-2 pr-2"
    >
      <span class="span-total col-12">
        Total R$
        {{
          form.valorTotalItens == 0
            ? '0,00'
            : form.valorTotalItens | formataValorPrecisaoDois
        }}
      </span>
    </div>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end mt-4">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
import { InputCliente } from '@components/inputs';
import OrcamentosService from '@common/services/cadastros/OrcamentosService';
import OrcamentoAnexoService from '@common/services/cadastros/OrcamentoAnexoService';
import { Requisitos } from '@components/requisitos';
import { OrcamentoModel } from '@common/models/cadastros/OrcamentoModel';
import UtilsService from '@common/services/UtilsService';
import ParticipanteService from '@common/services/cadastros/ParticipanteService';
import { DropdownModel } from '@common/models/geral/DropdownModel';
export default {
  components: {
    Requisitos,
    InputCliente,
  },
  props: ['id'],
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'numero',
            text: this.$t('modulos.orcamentos.formulario.numero'),
            width: 130,
          },
          {
            value: 'instrumento',
            text: this.$t('modulos.orcamentos.formulario.instrumento'),
            width: 160,
          },
          {
            value: 'descricao',
            text: this.$t('modulos.orcamentos.formulario.descricao'),
            cellClass: 'pl-0',
          },
          {
            value: 'prazoEntrega',
            text: this.$t('modulos.orcamentos.formulario.prazo'),
            width: 90,
            align: 'center',
          },
          {
            value: 'qtd',
            text: this.$t('modulos.orcamentos.formulario.qtd'),
            width: 80,
            align: 'center',
          },
          {
            value: 'valorUnitario',
            text: this.$t('modulos.orcamentos.formulario.valor_unitario'),
            width: 150,
            align: 'end',
          },
          {
            value: 'valorTotal',
            text: this.$t('modulos.orcamentos.formulario.valor_total'),
            width: 130,
            align: 'end',
          },
          {
            value: 'desconto',
            text: this.$t('modulos.orcamentos.formulario.desconto'),
            width: 140,
            align: 'end',
          },
          {
            value: 'frete',
            text: this.$t('modulos.orcamentos.formulario.frete'),
            width: 100,
            align: 'end',
          },
          {
            value: 'despesas',
            text: this.$t('modulos.orcamentos.formulario.despesas'),
            width: 120,
            align: 'end',
          },
        ],
        indexAtualInstrumento: null,
        numeroItemOrcamento: 1,
        numeroItemTipoServicoManutencaoSelecionados: null,
        numeroItemTipoServicoCalibracaoSelecionados: null,
        numeroItemPecasSelecionadas: null,
      },
      dadosRequisitosClienteFormatado: null,
      validacaoItensOrcamento: false,
      valido: false,
      prazoEntregaPadrao: null,
      prazoGarantia: null,
      indiceAba: 0,
      singleExpand: false,
      expanded: [],
      opcoes: {
        aosCuidadosDe: [],
        localExecucao: [],
        condicaoPagamento: [],
        tipoPessoa: helpers.TipoPessoaEnum,
        papelFornecedor: helpers.PapelFornecedorEnum,
        tipoDesconto: helpers.TipoDescontoEnum,
        diasColeta: helpers.DiasColetaEnum,
        booleano: helpers.BoleanoEnum,
        formaSolicitacaoOrcamento: helpers.FormaSolicitacaoOrcamentoEnum.sort(
          helpers.ordenarDropdown
        ),
      },
      form: new OrcamentoModel({
        orcamentoItem: [],
      }),
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.orcamentos.titulos.editar');
      return this.$t('modulos.orcamentos.titulos.novo');
    },
  },
  watch: {
    'form.participanteClienteNome': {
      handler() {
        if (
          !this.form.participanteClienteId &&
          this.form.participanteClienteNome
        ) {
          this.form.participanteClienteId =
            this.form.participanteClienteNome.value;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.listarLocalExecucao();
    this.listarCondicaoPagamento();
    this.listarDadosPadrao();
    this.buscarParticipanteConta();
    if (this.id) {
      this.buscar(this.id)
      helpers.redirecionarSemPermissao(this, 'Orcamento', 'Editar')
    }
    else {
      helpers.redirecionarSemPermissao(this, 'Orcamento', 'Inserir');
      this.form.valorTotalItens = 0;
    }
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    definirIconeAdicionadoRemovido: (item) => {
      let flag = false;
      if (item.orcamentoItemInstrumento?.length != 0)
        flag = item.orcamentoItemInstrumento[0].flagAdicionadoCliente;
      if (!flag)
        return {
          icon: '$successCircleOutline',
          color: 'primary-success-700--text',
        };
      return { icon: '$plusCircleOutline', color: 'warning--text' };
    },
    listarLocalExecucao: function () {
      this.loadingLocalExecucao = true;
      UtilsService.buscaLocalExecucao()
        .then((res) => {
          this.opcoes.localExecucao = new DropdownModel(res.data);
        })
        .finally(() => {
          this.loadingLocalExecucao = false;
        });
    },
    listarCondicaoPagamento: function () {
      this.loadingCondicaoPagamento = true;
      UtilsService.buscaCondicaoPagamento()
        .then((res) => {
          res?.data?.items?.map((item) => {
            this.opcoes.condicaoPagamento.push({
              text: item.nome,
              value: item.id,
            });
          });
        })
        .finally(() => {
          this.loadingCondicaoPagamento = false;
        });
    },
    dadosRequisitos: function (dados) {
      this.dadosRequisitosClienteFormatado = dados;
    },
    novaMensagem: function (dados) {
      this.form.orcamentoMensagem = dados;
    },

    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      OrcamentosService.buscar(this.id)
        .then((res) => {
          this.form = new OrcamentoModel(res.data);
          this.form.ordemServicoCodigo = res.data.ordemServico?.codigo;
          this.form.orcamentoItem.map((item) => {
            item.trocaSpanInputDescricao = false;
            item.trocaSpanInputPrazo = false;
            item.trocaSpanInputQuantidade = false;
            item.trocaSpanInputValorUnitario = false;
            item.trocaSpanInputValorTotal = false;
            item.trocaSpanInputDesconto = false;
            item.trocaSpanInputFrete = false;
            item.trocaSpanInputDespesas = false;
          });
          this.form.participanteClienteId = res.data.participanteCliente?.id;
          this.form.responsavelUsuarioNome = res.data.responsavelUsuario?.nome;
          this.form.ordemServico = res.data.ordemServico
            ? res.data.ordemServico?.codigo
            : null;

          this.buscaRequisitos(this.form.participanteClienteId);
          this.form.flagEmElaboracao =
            res.data.flagCondicaoPagamentoPorContrato;

          setTimeout(() => {
            this.form.dataEmissao = res.data.dataEmissao;
          }, 2000);
          this.form.participanteClienteNome =
            res.data.participanteCliente?.nome;
          this.form.participanteClienteId = res.data.participanteCliente?.id;
          this.form.participanteClienteContatoId =
            res.data.participanteCliente?.contatoId;
          this.form.participanteFornecedorId =
            res.data.participanteFornecedor?.id;
          this.form.participanteFornecedorNome =
            res.data.participanteFornecedor?.nome;
          this.form.localExecucaoId = res.data.localExecucao?.id;
          this.form.localExecucaoNome = res.data.localExecucao?.nome;
          this.form.condicaoPagamentoId = res.data.condicaoPagamento?.id;
          this.form.orcamentoItem.map((item) => {
            item.orcamentoItemInstrumento?.map((resItemInstrumento) => {
              if (!item.instrumentosNomes) item.instrumentosNomes = [];
              if (item.orcamentoItemInstrumento[0].id)
                item.orcamentoItemInstrumento = [];
              item.instrumentosNomes.push(resItemInstrumento.instrumento.nome);
              item.orcamentoItemInstrumento.push({
                instrumentoId: resItemInstrumento.instrumento.id,
                flagAdicionadoCliente: resItemInstrumento.flagAdicionadoCliente,
              });
            });
          });

          this.form.orcamentoItem?.map((item) => {
            item.orcamentoItemDetalhe?.map((itemDetalhe) => {
              itemDetalhe.codigo = itemDetalhe.servico
                ? itemDetalhe.servico?.nome
                : itemDetalhe.item?.nome;
              itemDetalhe.tipo = itemDetalhe.servico ? 'serviço' : 'peça';
              itemDetalhe.tipoServico = itemDetalhe.servico
                ? itemDetalhe.servico.tipoServico
                : null;
              itemDetalhe.servicoId = itemDetalhe.servico
                ? itemDetalhe.servico.id
                : null;
              itemDetalhe.idItem = itemDetalhe.servico
                ? itemDetalhe.servico.id
                : itemDetalhe.item.id;
              itemDetalhe.itemId = itemDetalhe.item
                ? itemDetalhe.item.id
                : null;
            });
          });

          this.form.dadosMensagem = this.form.orcamentoMensagem;
          this.form.orcamentoMensagem = [];
          res.data.orcamentoMensagem.forEach((item) => {
            item.mensagemOrcamentoId = item.mensagemOrcamento?.id;
            item.id = item.mensagemOrcamentoId;
            item.mensagemOrcamento.descricaoClassificacao =
              item.mensagemOrcamento?.classificacao;
            this.form.orcamentoMensagem.push({
              ...item,
              ...item?.mensagemOrcamento,
            });
          });
          this.form.orcamentoItem.sort((a, b) => a.numeroItem - b.numeroItem);
        })
        .catch(() => {
          this.toastErro(
            this.$t('modulos.orcamentos.erros.orcamento_invalido')
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.valido = true;
        });
    },
    buscaRequisitos(id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ParticipanteService.buscaRequisitosParticipante(
        id,
        'tipoRequisito=6&tipoRequisito=7'
      )
        .then((res) => {
          if (res?.data) this.dadosRequisitos(res?.data);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if (this.validacaoCamposItensOrcamento()) return;
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    validacaoCamposItensOrcamento() {
      this.validacaoItensOrcamento = false;
      this.form.orcamentoItem.map((item) => {
        if (!item.orcamentoItemDetalhe.length) {
          this.validacaoItensOrcamento = true;
          this.toastAlerta(this.$t(`geral.erros.insira_servico_peca`));
          return;
        }
        if (item.orcamentoItemDetalhe.length) {
          item.orcamentoItemDetalhe.map((itemDetalhe) => {
            if (!itemDetalhe.prazoEntrega) {
              this.validacaoItensOrcamento = true;
              this.toastAlerta(
                this.$t(`geral.erros.prazo_entrega_preencher_item_detalhe`)
              );
            }
            if (!itemDetalhe.quantidade) {
              this.validacaoItensOrcamento = true;
              this.toastAlerta(
                this.$t(`geral.erros.quantidade_preencher_item_detalhe`)
              );
            }
            if (!itemDetalhe.valorUnitario) {
              this.validacaoItensOrcamento = true;
              this.toastAlerta(
                this.$t(`geral.erros.valor_unitario_preencher_item_detalhe`)
              );
            }
            if (!itemDetalhe.valorTotal) {
              this.validacaoItensOrcamento = true;
              this.toastAlerta(
                this.$t(`geral.erros.valor_total_preencher_item_detalhe`)
              );
            }
            if (itemDetalhe.valorFrete == null) {
              itemDetalhe.valorFrete = 0;
            }
            if (itemDetalhe.outrasDespesas == null) {
              itemDetalhe.outrasDespesas = 0;
            }
            if (itemDetalhe.desconto == null) {
              itemDetalhe.desconto = 0;
            }
          });
        }

        if (!item.descricao) {
          this.validacaoItensOrcamento = true;
          this.toastAlerta(this.$t(`geral.erros.descricao_preencher`));
        }
        if (!item.prazoEntrega) {
          this.validacaoItensOrcamento = true;
          this.toastAlerta(this.$t(`geral.erros.prazo_entrega_preencher`));
        }
        if (!item.quantidade) {
          this.validacaoItensOrcamento = true;
          this.toastAlerta(this.$t(`geral.erros.quantidade_preencher`));
        }
        if (!item.valorUnitario) {
          this.validacaoItensOrcamento = true;
          this.toastAlerta(this.$t(`geral.erros.valor_unitario_preencher`));
        }
        if (!item.valorTotal) {
          this.validacaoItensOrcamento = true;
          this.toastAlerta(this.$t(`geral.erros.valor_total_preencher`));
        }
        if (item.valorFrete == null) {
          item.valorFrete = 0;
        }
        if (item.outrasDespesas == null) {
          item.outrasDespesas = 0;
        }
        if (item.desconto == null) {
          item.desconto = 0;
        }
      });

      return this.validacaoItensOrcamento;
    },
    setarFlagsFalsoInstrumentoServico() {
      this.form.orcamentoItem?.forEach((item) => {
        item.orcamentoItemDetalhe?.forEach((itemDetalhe) => {
          itemDetalhe.flagRemovidoCliente = false;
        });
        item.orcamentoItemInstrumento?.forEach((itemInstrumento) => {
          itemInstrumento.flagAdicionadoCliente = false;
        });
      });
    },
    gerenciarMensagens() {
      this.form.orcamentoMensagem?.forEach((mensagem) => {
        mensagem.id = null;
      });
    },
    salvar: async function () {
      this.gerenciarMensagens();
      if (this.$refs['dados-orcamento']) {
        this.valido = this.$refs['dados-orcamento'].$refs.form.validate();
      }
      if (this.form.orcamentoItem.length == 0) {
        this.valido = false;
        this.toastAlerta(this.$t(`geral.erros.insira_itens_orcamento`));
        return;
      }
      if (!this.valido) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      if (this.form.flagEmElaboracao) this.form.status = 6;
      else this.form.status = 4;
      await this.salvarAnexosInseridos();
      this.setarFlagsFalsoInstrumentoServico();
      if (!this.form.participanteClienteId) {
        this.form.participanteClienteId =
          this.form.participanteClienteNome.value;
      }
      OrcamentosService.salvar(this.form)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.orcamentos.cadastro_sucesso`));
          this.$router.push({ name: 'orcamentos' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.$router.push({ name: 'operacao' });
    },
    listarDadosPadrao() {
      UtilsService.buscarDadosPadrao(this.id)
        .then((res) => {
          this.prazoEntregaPadrao = res.data.prazoEntregaPadrao;
          this.prazoGarantia = res.data.prazoGarantia;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.orcamentos.erros.id_invalido'));
        });
    },
    salvarAnexosInseridos() {
      const promises = [];

      this.form.anexosRemovidos?.forEach((id) => {
        promises.push(OrcamentoAnexoService.excluir(id));
      });
      this.form.anexosAdicionados?.forEach((anexo) => {
        anexo.orcamentoId = this.form.id;
        promises.push(OrcamentoAnexoService.salvar(anexo));
      });

      return promises;
    },
  },
};
</script>
